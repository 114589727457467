import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

class MultiRange extends HTMLElement {
	constructor() {
		super();
		this.min = parseInt(this.getAttribute("min"));
		this.max = parseInt(this.getAttribute("max"));

		this.minInput = this.querySelector(`#${this.getAttribute("min-input")}`);
		this.maxInput = this.querySelector(`#${this.getAttribute("max-input")}`);
	}

	reset() {
		this.minInput.value = "";
		this.maxInput.value = "";
		this.noUiSlider.set([this.min, this.max]);
		this.minInput.dispatchEvent(new Event("change"));
	}

	connectedCallback() {
		const format = this.getAttribute("format");
		const urlParams = new URLSearchParams(window.location.search);
		const min = urlParams.get(this.minInput.getAttribute("name"));
		const max = urlParams.get(this.maxInput.getAttribute("name"));

		const slider = noUiSlider.create(this, {
			start: [min || this.min, max || this.max],
			connect: true,
			range: {
				min: this.min,
				max: this.max,
			},
			tooltips: true,
			format: {
				to: function (value) {
					if (format == "currency") {
						return `$${parseInt(value)}`;
					} else {
						if (parseInt(value) === 18) {
							// cheating, should really be if v == max
							return `${parseInt(value)}+`;
						}
					}
					return parseInt(value);
				},
				from: function (value) {
					if (format == "currency") {
						return parseInt(value.replace("$", ""));
					}
					return parseInt(value);
				},
			},
		});

		slider.on("change.0", (values, handle) => {
			const value =
				format === "currency" ? values[0].replace("$", "") : values[0];
			const newValue = parseInt(value);
			if (this.minInput.value != newValue) {
				this.minInput.value = newValue;
				this.minInput.dispatchEvent(new Event("change"));
			}
		});
		slider.on("change.1", (values, handle) => {
			const value =
				format === "currency" ? values[1].replace("$", "") : values[1];

			const newValue = parseInt(value);
			if (this.maxInput.value != newValue) {
				this.maxInput.value = newValue;
				this.maxInput.dispatchEvent(new Event("change"));
			}
		});
	}
}

window.customElements.get("multi-range") ||
	window.customElements.define("multi-range", MultiRange);
