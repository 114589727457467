import Swiper from "swiper";
import { Thumbs } from "swiper/modules";

function initSlider(swiperElement, thumbs) {
	let vertical = "vertical" in swiperElement.dataset;
	let slidesAuto = "slidesAuto" in swiperElement.dataset;
	let slidesPerView = swiperElement.dataset.slidesPerView;

	let config = {
		slidesPerView: 1,
		spaceBetween: 8,
		breakpoints: {
			1024: {
				slidesPerView: 1,
			},
		},
	};

	let prevButton = swiperElement.parentElement.querySelector(
		".swiper-button-prev",
	);
	let nextButton = swiperElement.parentElement.querySelector(
		".swiper-button-next",
	);
	if (prevButton && nextButton && thumbs) {
		config.navigation = {
			prevEl: prevButton,
			nextEl: nextButton,
		};
	}

	if (vertical) {
		config.direction = "vertical";
	}
	if (slidesAuto) {
		config.slidesPerView = "auto";
		config.breakpoints = {};
	} else if (slidesPerView) {
		config.slidesPerView = parseInt(slidesPerView);
		config.breakpoints = {};
	}
	if (thumbs) {
		config.modules = [Thumbs];
		config.thumbs = {
			swiper: thumbs.swiper,
		};
	}

	const swiper = new Swiper(swiperElement, config);
	swiperElement.swiper = swiper;
}

function init() {
	let productSwipers = document.querySelectorAll("[data-product-swiper]");

	if (!productSwipers) {
		return;
	}

	productSwipers.forEach((swiper) => {
		let swiperThumbs = swiper.parentElement.parentElement.querySelector(
			"[data-product-swiper-thumbs]",
		);

		initSlider(swiperThumbs);
		initSlider(swiper, swiperThumbs);

		swiper.addEventListener("htmx:afterSwap", function (e) {
			initSlider(swiperThumbs);
			initSlider(swiper, swiperThumbs);
		});
		swiperThumbs.addEventListener("htmx:afterSwap", function (e) {
			initSlider(swiperThumbs);
			initSlider(swiper, swiperThumbs);
		});
	});
}

export default init;
