import collapse from "@alpinejs/collapse";
import Alpine from "alpinejs";
import { Navigation } from "swiper/modules";

const swiper = require("swiper");

const Swiper = swiper.Swiper;
Swiper.use([Navigation]);

Alpine.store("responsive", {
	width: window.innerWidth,
	desktop: true,
	breakpoint: 1279,

	init() {
		this.onResize();
	},

	onResize() {
		this.width = document.documentElement.clientWidth;
		this.desktop = this.width > this.breakpoint;
	},
});

Alpine.store("productModal", {
	open: false,

	init() {
		document.body.style.overflow = "";
	},

	openModal() {
		this.open = true;
		document.body.style.overflow = "hidden";
	},

	closeModal() {
		this.open = false;
		document.body.style.overflow = "";
	},
});

Alpine.data("productModal", () => ({
	init() {
		window.addEventListener("slide-clicked", (e) => {
			let swiperElement = e.detail.swiperElement;
			if (!swiperElement) {
				return;
			}
			let activeSlide = swiperElement.swiper.slides.find((slide) =>
				slide.classList.contains("swiper-slide-active"),
			);
			let index = swiperElement.swiper.slides.indexOf(activeSlide);

			this.$refs.productModal
				.querySelectorAll("[data-product-swiper]")
				.forEach((s) => {
					if (s.swiper) {
						s.swiper.slideTo(index);
						s.swiper.update();
					}
				});

			Alpine.store("productModal").openModal();
		});
	},
}));

Alpine.data("drawer", (initialOpenState = false) => ({
	open: initialOpenState,

	init() {
		document.body.style.overflow = "";
		this.$watch("open", (value) => {
			document.body.style.overflow = value ? "hidden" : "";
		});
		Alpine.effect(() => {
			const desktop = Alpine.store("responsive").desktop;
			this.open = this.open & !desktop;
		});
	},

	toggle() {
		this.open = !this.open;
	},
}));

Alpine.data("searchSelect", () => ({
	searchTerm: "",

	clear() {
		this.searchTerm = "";
		let inputs = this.$root.querySelectorAll("input");
		this.$root.querySelectorAll("input:checked").forEach((el) => {
			el.checked = false;
		});
		inputs[inputs.length - 1].dispatchEvent(new Event("change"));
	},

	show(el) {
		if (this.searchTerm.length === 0) {
			return true;
		}
		let label = el.querySelector("label").textContent.toLowerCase();
		return label
			.replace(/\s/g, "")
			.includes(this.searchTerm.toLowerCase().replace(/\s/g, ""));
	},
}));

Alpine.data("viewAllCheckbox", (initialOnState = true) => ({
	on: initialOnState,
	checkboxes: [],

	init() {
		this.checkboxes = this.$refs.container.querySelectorAll(
			"input[type=checkbox]",
		);
		this.toggleViewAll();
		this.checkboxes.forEach((checkbox) =>
			checkbox.addEventListener("change", () => this.toggleViewAll()),
		);
		this.$refs.viewAllPill.classList.remove("!transition-none");
	},

	toggleViewAll() {
		const anyChecked = Array.from(this.checkboxes).some(
			(checkbox) => checkbox.checked,
		);
		if (this.$refs.viewAllPill) {
			this.$refs.viewAllPill.classList.toggle("!bg-green", !anyChecked);
			this.$refs.viewAllPill.classList.toggle("!border-green", !anyChecked);
			this.$refs.viewAllPill.classList.toggle("!text-white", !anyChecked);
		}
	},

	viewAll() {
		this.checkboxes.forEach((checkbox) => (checkbox.checked = false));
		this.toggleViewAll();
	},
}));

Alpine.data("swiper", ({ mobileOnly = false }) => ({
	swiper: null,
	mobileOnly: mobileOnly,

	init() {
		if (this.mobileOnly) {
			if (!this.$store.responsive.desktop) {
				this.initSwiper();
			}
			this.$watch("$store.responsive.desktop", (value) => {
				if (this.$store.responsive.desktop) {
					if (this.swiper) {
						this.swiper.destroy();
					}
				} else {
					this.initSwiper();
				}
			});
		} else {
			this.initSwiper();
		}
	},

	initSwiper() {
		this.swiper = new Swiper(this.$refs.container, {
			slidesPerView: 2,
			spaceBetween: 8,
			watchOverflow: true,
			breakpoints: {
				650: {
					slidesPerView: 3,
					spaceBetween: 8,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 16,
				},
				1280: {
					slidesPerView: 5,
					spaceBetween: 16,
				},
			},
			navigation: {
				nextEl: this.$refs.next,
				prevEl: this.$refs.prev,
			},
		});
	},
}));

Alpine.plugin(collapse);
window.Alpine = Alpine;
Alpine.start();
