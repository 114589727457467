import "../styles/nsb.css";

import "./includes/htmx";
import "./includes/alpine";
import product from "./includes/product";
import youtubeEmbed from "./includes/youtubeEmbed";
import vimeoEmbed from "./includes/vimeoEmbed";
import sentryInit from "../../global/javascript/sentry";

import "./components/multi-range";

document.addEventListener("DOMContentLoaded", () => {
	if (window.ENV == "PRODUCTION") {
		sentryInit();
	}
	youtubeEmbed();
	vimeoEmbed();
	if (document.querySelector(".product-page")) {
		product();
	}
});
